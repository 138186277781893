<template>
  <div>
    <banner></banner>
    <div class="content_area">
      <div class="table">
        <!-- <el-table
            :data="dataList"
            style="width: 100%"
            border
            :cell-class-name="cellClassNameFn"
            :span-method="tableSpanMethod"
        >
          <el-table-column label="" width="200" align="center">
            <template slot-scope="scope"><div class="english">{{ scope.row.way }}</div></template>
          </el-table-column>
          <el-table-column :label="$t('time')" width="160" align="center">
            <template slot-scope="scope"><span class="font">{{ scope.row.date }}</span></template>
          </el-table-column>
          <el-table-column :label="$t('times')" width="250" align="center">
            <template slot-scope="scope"><span class="font">{{ scope.row.time }}</span></template>
          </el-table-column>
          <el-table-column label="" align="center">
            <template slot-scope="scope">
              <div class="font english">{{ scope.row.adress }}</div>
              <span v-if="scope.row.webUrl" class="point color2292FF" @click="handleWebUrl">
                {{ scope.row.webUrl }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="" align="center">
            <template slot-scope="scope"><div class="font english">{{ scope.row.guests }}</div></template>
          </el-table-column>
          <el-table-column label="" align="center">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.guestss" :key="index" class="guestsItem">
                <div class="font english">{{ item }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table> -->
        <el-table
          :data="dataList"
          style="width: 100%"
          border
          :cell-class-name="cellClassNameFn"
          :span-method="objectSpanMethod"
        >
          <el-table-column label="" width="100" align="center">
            <template slot-scope="scope"
              ><div class="english">{{ scope.row.way }}</div></template
            >
          </el-table-column>
          <el-table-column :label="$t('time')" width="120" align="center">
            <template slot-scope="scope"
              ><span class="font">{{ scope.row.date }}</span></template
            >
          </el-table-column>
          <el-table-column :label="$t('times')" width="150" align="center">
            <template slot-scope="scope"
              ><span class="font">{{ scope.row.time }}</span></template
            >
          </el-table-column>
          <el-table-column
            :label="$t('meetingTitleAll')"
            align="center"
            width="350"
          >
            <template slot-scope="scope">
              <div class="font english">{{ scope.row.title }}</div>
            </template>
          </el-table-column>
          <el-table-column label="" align="center" width="100">
            <template slot-scope="scope">
              <span class="font">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('meetingUserAll')" align="center">
            <template slot-scope="scope"
              ><div class="font english">{{ scope.row.guests }}</div></template
            >
          </el-table-column>
          <!-- <el-table-column label="" align="center">
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.guestss"
                :key="index"
                class="guestsItem"
              >
                <div class="font english">{{ item }}</div>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "~exp/components/common/banner";
export default {
  name: "index",
  components: { banner },
  data() {
    return {
      dataList: [
        {
          way: this.$t("livechedule "),
          date: this.$t("22nd,April"),
          time: "09:00-09:30",
          title: "开幕致辞",
          guests: "锦程物流网 CEO",
          name: "邱俊傑",
        },
        {
          guests: "AILA CEO",
          name: "张梓靖",
        },
        {
          guests: "中国交通运输协会国际班列咨询服务中心 副主任",
          name: "武靖宇",
        },
        {
          title: "启动仪式",
          guests: "锦程物流网 CEO",
          name: "邱俊傑",
        },
        {
          guests: "AILA CEO",
          name: "张梓靖",
        },
        {
          guests: "上海市报关协会会长",
          name: "肖健",
        },
        {
          guests: "虹口工商联航运商会会长、美设国际物流集团股份有限公司董事长",
          name: "葛善根",
        },
        {
          guests: "中国（宁波）航交会组委会主席、宁波市国际联运协会会长",
          name: "乐振天",
        },
        {
          guests: "上海康顿特国际货运代理有限公司",
          name: "李建尔",
        },
        {
          time: "10:00-11:30",
          title: "《“一带一路”中欧班列高质量发展，促进世界经济格局平衡发展》",
          guests: "中国交通运输协会国际班列咨询服务中心 副主任研究员",
          name: "车探来",
        },
        {
          time: "13:30-13:55",
          title:
            "《 RCEP背景下跨境供应链机遇与挑战》主要介绍RCEP的由来和内容，分析RCEP对区域经济的影响，探讨RCEP对跨境供应链运营的机遇和挑战",
          guests: "上海交通大学安泰经济与管理学院 教授",
          name: "赵一飞",
        },
        {
          time: "14:05-14:30",
          title: "《数字化航运发展解析》",
          guests: "运去哪联合创始人&首席运营官",
          name: "徐杨",
        },
        {
          time: "14:40-15:40",
          title: "《国际物流企业的数字化之路如何走？》",
          guests: "中国（宁波）航交会组委会主席、宁波市国际联运协会会长",
          name: "乐振天",
        },
        {
          guests: "鸭嘴兽创始人",
          name: "唐红斌",
        },
        {
          guests: "货图科技创始人",
          name: "袁峰",
        },
        {
          guests: "宁波申洋董事长",
          name: "赵斌建",
        },
        {
          guests: "长江航线联盟创始人/武汉华航总经理",
          name: "李亢",
        },
        {
          time: "15:50-16:15",
          title: "《如何系统性的开展跨境电商业务》",
          guests: "宁波曼拓电子商务有限公司 CEO",
          name: "余拓",
        },
        {
          time: "16:20-16:45",
          title: "《数字关务及供应链》",
          guests: "云贸通平台事业部总经理",
          name: "张翚",
        },
        {
          date: this.$t("22nd,April"),
          time: "09:30-09:55",
          title: "《合作共赢，打造华北保税仓储分拨中心》",
          guests: "天津滨海松昌国际物流（集团）有限公司销售经理",
          name: "吕宜庆",
        },
        {
          time: "10:00-10:25",
          title: "《爱拼：助力供应链提效降本》",
          guests: "爱拼物流科技有限公司总经理",
          name: "易江河",
        },
        {
          time: "10:30:10:55",
          title: "《携手嘀咑购ETSG，共赢跨境电商新机遇》",
          guests: "嘀咑购ETSG总经理助理",
          name: "钱煜",
        },
        {
          time: "11:00-11:25",
          title: "《如何构建航运数字化服务体系",
          guests: "上海鲸准物流科技有限公司首席运营官",
          name: "王洋",
        },
      ],
    };
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex == 0) {
          return {
            rowspan: 25,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
      }
      if (columnIndex === 2) {
        if (rowIndex === 0) {
          return {
            rowspan: 9,
            colspan: 1,
          };
        } else if (
          rowIndex === 9 ||
          rowIndex === 10 ||
          rowIndex === 11 ||
          rowIndex > 16
        ) {
          return {
            rowspan: 1,
            colspan: 1,
          };
        } else if (rowIndex === 12) {
          return {
            rowspan: 5,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
      }
      if (columnIndex === 1) {
        if (rowIndex === 0) {
          return {
            rowspan: 19,
            colspan: 1,
          };
        }
        if (rowIndex >= 19) {
          return {
            rowspan: 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
      }
      if (columnIndex === 3) {
        if (rowIndex === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          };
        } else if (rowIndex === 3) {
          return {
            rowspan: 6,
            colspan: 1,
          };
        } else if (
          rowIndex === 9 ||
          rowIndex === 10 ||
          rowIndex === 11 ||
          rowIndex > 16
        ) {
          return {
            rowspan: 1,
            colspan: 1,
          };
        } else if (rowIndex === 12) {
          return {
            rowspan: 5,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
      }
    },
    tableSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 10,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 1) {
        if (rowIndex === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          };
        }
        //  else if (rowIndex === 1) {
        //   return {
        //     rowspan: 5,
        //     colspan: 1,
        //   };
        // } else if (rowIndex === 6) {
        //   return {
        //     rowspan: 4,
        //     colspan: 1,
        //   };
        // } else if (rowIndex === 10) {
        //   return {
        //     rowspan: 4,
        //     colspan: 1,
        //   };
        // } else if (rowIndex === 14) {
        //   return {
        //     rowspan: 4,
        //     colspan: 1,
        //   };
        // } else {
        //   return {
        //     rowspan: 1,
        //     colspan: 0,
        //   };
        // }
      }
      // if (columnIndex === 3) {
      //   if (rowIndex === 0) {
      //     return {
      //       rowspan: 10,
      //       colspan: 2,
      //     };
      //   } else if (rowIndex === 10) {
      //     return {
      //       rowspan: 8,
      //       colspan: 1,
      //     };
      //   } else {
      //     return {
      //       rowspan: 1,
      //       colspan: 0,
      //     };
      //   }
      // }
      // if (columnIndex === 5) {
      //   if (rowIndex === 0) {
      //     return {
      //       rowspan: 0,
      //       colspan: 1,
      //     };
      //   } else if (rowIndex === 10) {
      //     return {
      //       rowspan: 8,
      //       colspan: 1,
      //     };
      //   } else {
      //     return {
      //       rowspan: 1,
      //       colspan: 0,
      //     };
      //   }
      // }
    },
    cellClassNameFn({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0 && columnIndex === 0) {
        return "exhibitionagendaBgFFF8DE";
      }
      //  else if (columnIndex === 0 && rowIndex === 10) {
      //   return "exhibitionagendaBgFFA7AB";
      // } else if (columnIndex === 3 && rowIndex === 10) {
      //   return "exhibitionagendaBgFFE3E4";
      // }
    },
    handleWebUrl() {
      window.open("https://www.expo56.com/");
    },
  },
};
</script>

<style scoped lang="less">
.table {
  padding: 30px 0 71px;
  .font {
    font-size: 18px;
    font-weight: 800;
    color: #1f292e;
  }
  .guestsItem {
    padding-top: 14px;
  }
}

/deep/ .el-table th {
  padding: 10px;
  font-size: 16px;
  background: #f7f7f7;
  .cell {
    color: #1f292e;
    font-size: 18px;
    font-weight: 800;
  }
}
/deep/ .el-table thead tr th {
  border-right: none !important;
}
/deep/ .el-table tbody tr:hover > td {
  background-color: #ffffff;
}
// /deep/ .el-table tbody tr:nth-child(1):hover > td:nth-child(1) {
//   background: #fff8de;
// }
// /deep/ .el-table tbody tr:nth-child(1):hover > td:nth-child(4) {
//   background: #fff8de;
// }
// /deep/ .el-table tbody tr:nth-child(11):hover > td:nth-child(1) {
//   background: #ffa7ab;
// }
// /deep/ .el-table tbody tr:nth-child(11):hover > td:nth-child(4) {
//   background: #ffe3e4;
// }
.english {
  word-break: keep-all;
}
.color2292FF {
  color: #2292ff;
}
</style>
<style lang="less">
.exhibitionagendaBgFFF8DE {
  background: #fff8de;
  /deep/ .cell {
    font-size: 21px;
    color: #575353;
    font-weight: 800;
    padding: 30px !important;
    line-height: 150%;
  }
}
.exhibitionagendaBgFFA7AB {
  background: #ffa7ab;
  /deep/ .cell {
    font-size: 21px;
    color: #ffffff;
    font-weight: 800;
    padding: 30px !important;
    line-height: 150%;
  }
}
.exhibitionagendaBgFFE3E4 {
  background: #ffe3e4;
}
</style>